<template>
  <el-main>
    <div class="flexbox">
      <div class="row1">
        <div style="text-align: left">
          <div class="row1_text">{{ account.account }}</div>
          <div class="title">提现账号</div>
        </div>
        <router-link to="/finance/account" style="text-align: right">
          <el-button size="small" class="row1_btn">编辑</el-button>
        </router-link>
      </div>
      <div class="row1">
        <div style="text-align: left">
          <div class="row1_price">
            {{ account.withdrawable_amount }}
          </div>
          <div class="title">可提现金额（待提现）</div>
        </div>
        <el-button plain size="small" style="border: 1px solid #409eff" class="row1_btn" @click="withdraw">提现</el-button>
      </div>
      <div class="row1">
        <div class="row1_price">
          {{ account.amount_settled }}
        </div>
        <div class="title">已结算金额（已结算）</div>
      </div>
      <div class="row1">
        <div class="row1_price">
          <span>￥</span>
          {{ account.outstanding_amount }}
        </div>
        <div class="title">待结算金额（审核中）</div>
      </div>
      <div class="row1">
        <div class="row1_price">
          {{ account.freeze_amount }}
        </div>
        <div class="title">冻结金额（冻结中）</div>
      </div>
    </div>
    <el-table style="width: 100%; border-left: 1px solid #e8eef1; border-right: 1px solid #e8eef1" :data="list" fit header-cell-style="background-color:#f8f9fa;">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="订单编号" align="center" prop="pay_sn" width="200"></el-table-column>
      <el-table-column label="订单金额" align="center" prop="amount">
        <template v-slot="{ row }">
          {{ row.amount }}
          <el-tooltip placement="top" effect="light">
            <div slot="content" class="tooltip">
              <p>
                <span>订单商品总额：{{ row.goods_amount }}</span>
                <span>余额抵扣金额：{{ row.discount_reduction_amount }}</span>
              </p>
              <p>
                <span>快递金额：{{ row.freight_fee }}</span>
                <span>会员折扣金额：{{ row.pd_amount }}</span>
              </p>
              <p>
                <span>订单改价金额：{{ row.revise_amount }}</span>
                <span>优惠券金额：{{ row.reduction_amount }}</span>
              </p>
              <p>
                <span>运费改价金额：{{ row.revise_freight_fee }}</span>
                <span>实际支付金额：{{ row.amount }}</span>
              </p>
            </div>
            <i class="el-icon-warning" style="color: #409eff"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="抽成费率" align="center" prop="rake" width="160"></el-table-column>
      <el-table-column label="抽成费用" align="center" prop="commission" width="210"></el-table-column>
      <el-table-column label="可结算金额" align="center" prop="settlement_amount"></el-table-column>
      <el-table-column label="订单完成时间" align="center" prop="settlement_amount"></el-table-column>
      <el-table-column label="账单状态" align="center">
        <template v-slot="{ row }">
          <div :style="{ color: billStatusArr[row.withdraw_status].color }">
            {{ row.withdraw_status == 0 ? '已驳回' : row.withdraw_status == 1 ? '待提现' : row.withdraw_status == 2 ? '待审核' : row.withdraw_status == 3 ? '待结算' : '已结算' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data: function () {
    return {
      account: {
        bank: '',
        orderacount: '',
        frozenamount: '',
        withdrawalamount: '',
      },
      list: [],
      total: 0,
      page: 1,
      pageNum: 10,
      billStatusArr: [
        {
          value: 0,
          label: '已驳回',
          color: '#F56C6C',
        },
        {
          value: 1,
          label: '待提现',
          color: '#F56C6C',
        },
        {
          value: 2,
          label: '待审核',
          color: '#E6A23C',
        },
        {
          value: 3,
          label: '待结算',
          color: '#E6A23C',
        },
        {
          value: 4,
          label: '已结算',
          color: '#3772FB',
        },
      ],
    };
  },
  created() {
    this.getAccountInfo();
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
    },
    withdraw() {
      if (this.account.withdrawable_amount > 0) {
        this.$confirm('确认是否提现？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios.post(this.$api.finance.withdraw).then((res) => {
            if (res.code == 0) {
              this.$message.success('提现成功');
              this.$router.push('/finance/withdrawLog');
            } else {
              this.$message.error(res.msg);
            }
          });
        });
      }else{
        this.$message.warning('暂无提现金额')
      }
    },
    getList() {
      this.$axios.post(this.$api.finance.withdrawnList).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map((item) => (item.finnshed_time = getDateformat(item.finnshed_time)));
          this.list = list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getAccountInfo() {
      this.$axios.post(this.$api.finance.accountInfo).then((res) => {
        if (res.code == 0) {
          if (res.result.type == 1 || res.result.type == 2) {
            res.result.account = res.result.bank_card;
          } else {
            res.result.account = res.result.nickname;
          }
          this.account = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tooltip {
  p {
    display: flex;
    margin-top: 2px;
    span {
      width: 140px;
      color: #409eff;
    }
  }
}
.el-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  .editAccount {
    .el-input {
      width: 330px;
    }
  }
}
.flexbox {
  display: flex;
  align-items: center;
  .row1 {
    height: 105px;
    border-radius: 8px;
    color: #2e2e2e;
    flex: 1;
    padding: 20px;
    margin-right: 26px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #edeef0;
    &:nth-child(1) {
      flex: none;
      width: 380px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      border: 0;
      background: url('~@/assets/image/cw_back.png') no-repeat;
      .title {
        font-size: 14px;
      }
      .row1_text {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
    &:nth-child(2) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      color: #979899;
    }
    .row1_text {
      font-size: 14px;
    }
    .row1_price {
      font-size: 30px;
      margin-bottom: 10px;
      font-weight: bold;
      color: #333;
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }
}
/deep/.el-table__header .operation {
  padding-left: 50px;
}
.tablefooter {
  height: 48px;
  width: 100%;
  background-color: #e8eef1;
  display: flex;
  align-items: center;
  padding: 0 14px;
  justify-content: space-between;
}
</style>
